.screensaver-container {
  display: flex;
  background-color: #000;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.screensaver {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 9;
  height: 100%;
  width: 100%;
}
.leave-screensaver {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
}

.screensaver-button {
  /* padding: 30px 20px; */
  height: 100%;
  font-size: 36px;
  letter-spacing: 1px;
  word-spacing: 5px;
}
